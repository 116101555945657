import React, { useState, useContext } from 'react'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import * as Yup from 'yup';

import Card from '../card'
import FieldText from '../field/formikText'
import Button from './../button'
import Messages from './../messages'
import Icon from './../icon'
import { resendEmail } from '../../services/clientResend';
import MessagesContext from '../../context/messages';

const FormSchema = Yup.object().shape({
  email: Yup.string()
    .email('Invalid email')
    .required('Please enter an Email')
});

const ResendEmailForm = ({ type, description, clientId }) => {
  const { messages, setMessages } = useContext(MessagesContext)

  return (
    <div className="container-panel">
      <Card>
        <Formik
          initialValues={
            {
              email: '',
            }
          }
          validate={values => { }}
          validationSchema={FormSchema}
          onSubmit={(values, { setSubmitting, setErrors }) => {
            setSubmitting(true);
            setMessages([]);
            resendEmail(clientId, {
              email: values.email,
              type
            })
              .then(response => {
                setMessages([
                  {
                    type: 'status',
                    text: response.data.message
                  }
                ]);
                setSubmitting(false)
              })
              .catch(error => {
                if (error.response.data.errors) {
                  setMessages(
                    error.response.data.errors.map(error => ({
                      type: 'error',
                      text: error.title
                    }))
                  );
                }
                setErrors({
                  email: 'Email not registered for this client',
                })
                setSubmitting(false)
              })
          }}
        >
          {({ values, errors, touched, setFieldValue, handleChange, handleBlur, isSubmitting }) => (
            <Form>
              <div className="card--inner">
                <h2 className="card--title">JOCRF</h2>
                <Messages messages={messages} />
                {description && (
                  <div className="card--description"><p>{description}</p></div>
                )}
                <div className="layout--fields-centered">
                  <div>
                    <Field
                      type="email"
                      id={'email'}
                      name={'email'}
                      required={true}
                    >
                      {({ field, form }) => (
                        <FieldText
                          type="text"
                          {...field }
                          onChange={(event) => { setFieldValue(event.target.name, event.target.value.trim()); }}
                          isValid={!form.errors[field.name]}
                          label="Email Address"
                          required={true} />
                      )}
                    </Field>
                    <ErrorMessage name="email" render={msg => <div className="error">{msg}</div>} />
                  </div>
                </div>
              </div>
              <div className="card--inner card--divider layout-add-submit">
                <Button theme="primary" type="submit" disabled={isSubmitting}>
                  <Icon icon="envelope" theme="primary" />
                  <span disabled={isSubmitting} className="button__label">Resend Email</span>
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </Card>
    </div>
  )
}

export default ResendEmailForm
