/**
 *  API: appointment
 */

import React from 'react';
import PropTypes from 'prop-types';
import { useLoads } from 'react-loads';
import { formatError } from '../lib/request';
import axios from 'axios';
import { getUrl } from './../lib/routes';

const getRoute = clientId => `/api/clients/${clientId}/resend`;

const request = (route, options) => new Promise(
  (resolve, reject) => {
    axios(
      getUrl(route),
      {
        ...options,
      }
    )
      .then(resolve)
      .catch(error => {
        reject(formatError(error))
      })
  }
);

export const setData = data => {
  const bodyFormData = new FormData()

  for (const param in data) {
    if (data.hasOwnProperty(param)) {
      bodyFormData.append(param, data[param])
    }
  }

  return bodyFormData
}

export const resendEmail = (clientId, data) => request(getRoute(clientId), {
  method: 'post',
  data: setData(data)
});

// Create a Context
const ClientResendAPIContext = React.createContext();

/**
 * Gets the data from a API response.
 * @param {Object} response
 *  The response returned from an API request
 * @returns {Object}
 *  The raw data from the API response
 */
const getDataFromResponse = response => {
  if (response && response.data) {
    return response.data;
  }

  return {};
}

export const ClientResendAPIProvider = ({ children }) => {
  const loader = useLoads('client-resend', resendEmail, {
    defer: true
  });

  // Raw response data
  const data = getDataFromResponse(loader.response);

  return (
    <ClientResendAPIContext.Provider value={ Object.assign({}, loader, {
      data
    })}>
      {children}
    </ClientResendAPIContext.Provider>
  )
}

ClientResendAPIProvider.propTypes = {
  children: PropTypes.element
}

export default ClientResendAPIContext
